import type { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';
import { withStyledSystem } from '@metropolis-io/theme';
import type {
  StyledSystemProps,
  AnimatedStyledComponent,
} from '@metropolis-io/theme';

export type TextProps = HTMLAttributes<HTMLSpanElement> & StyledSystemProps;

export const Text = styled.span<StyledSystemProps>`
  font-family: "Neue Montreal", NeueMontreal-Medium, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1;
  ${withStyledSystem}
`;

export const TextBold = styled.span<StyledSystemProps>`
  font-family: "Neue Montreal", NeueMontreal-Bold, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1;
  ${withStyledSystem}
`;

export const AnimatedText: AnimatedStyledComponent<typeof Text> = animated(Text);
AnimatedText.defaultProps = {
  display: 'inline-flex',
};

export const AnimatedTextBold: AnimatedStyledComponent<typeof TextBold> = animated(TextBold);
AnimatedTextBold.defaultProps = {
  display: 'inline-flex',
};
