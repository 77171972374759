import { memo } from 'react';
import { createGlobalStyle } from 'styled-components';

import NeueMontrealBoldWoff2 from '../../fonts/NeueMontreal-Bold.woff2';
import NeueMontrealBoldWoff from '../../fonts/NeueMontreal-Bold.woff';
import NeueMontrealBoldOtf from '../../fonts/NeueMontreal-Bold.otf';

import NeueMontrealBoldItalicWoff2 from '../../fonts/NeueMontreal-BoldItalic.woff2';
import NeueMontrealBoldItalicWoff from '../../fonts/NeueMontreal-BoldItalic.woff';
import NeueMontrealBoldItalicOtf from '../../fonts/NeueMontreal-BoldItalic.otf';

import NeueMontrealItalicWoff2 from '../../fonts/NeueMontreal-Italic.woff2';
import NeueMontrealItalicWoff from '../../fonts/NeueMontreal-Italic.woff';
import NeueMontrealItalicOtf from '../../fonts/NeueMontreal-Italic.otf';

import NeueMontrealLightWoff2 from '../../fonts/NeueMontreal-Light.woff2';
import NeueMontrealLightWoff from '../../fonts/NeueMontreal-Light.woff';
import NeueMontrealLightOtf from '../../fonts/NeueMontreal-Light.otf';

import NeueMontrealLightItalicWoff2 from '../../fonts/NeueMontreal-LightItalic.woff2';
import NeueMontrealLightItalicWoff from '../../fonts/NeueMontreal-LightItalic.woff';
import NeueMontrealLightItalicOtf from '../../fonts/NeueMontreal-LightItalic.otf';

import NeueMontrealMediumWoff2 from '../../fonts/NeueMontreal-Medium.woff2';
import NeueMontrealMediumWoff from '../../fonts/NeueMontreal-Medium.woff';
import NeueMontrealMediumOtf from '../../fonts/NeueMontreal-Medium.otf';

import NeueMontrealMediumItalicWoff2 from '../../fonts/NeueMontreal-MediumItalic.woff2';
import NeueMontrealMediumItalicWoff from '../../fonts/NeueMontreal-MediumItalic.woff';
import NeueMontrealMediumItalicOtf from '../../fonts/NeueMontreal-MediumItalic.otf';

import NeueMontrealRegularWoff2 from '../../fonts/NeueMontreal-Regular.woff2';
import NeueMontrealRegularWoff from '../../fonts/NeueMontreal-Regular.woff';
import NeueMontrealRegularOtf from '../../fonts/NeueMontreal-Regular.otf';

const WebFonts = memo(createGlobalStyle`
  @font-face {
    font-family: 'Neue Montreal';
    src: local('NeueMontreal-Bold'),
        local('Neue Montreal'),
        url(${NeueMontrealBoldWoff2}) format('woff2'),
        url(${NeueMontrealBoldWoff}) format('woff'),
        url(${NeueMontrealBoldOtf}) format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'NeueMontreal-Bold';
    src: local('NeueMontreal-Bold'),
        url(${NeueMontrealBoldWoff2}) format('woff2'),
        url(${NeueMontrealBoldWoff}) format('woff'),
        url(${NeueMontrealBoldOtf}) format('opentype');
      font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: local('NeueMontreal-BoldItalic') ,
        local('Neue Montreal'),
        url(${NeueMontrealBoldItalicWoff2}) format('woff2'),
        url(${NeueMontrealBoldItalicWoff}) format('woff'),
        url(${NeueMontrealBoldItalicOtf}) format('opentype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'NeueMontreal-BoldItalic';
    src: local('NeueMontreal-BoldItalic'),
        url(${NeueMontrealBoldItalicWoff2}) format('woff2'),
        url(${NeueMontrealBoldItalicWoff}) format('woff'),
        url(${NeueMontrealBoldItalicOtf}) format('opentype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: local('NeueMontreal-Italic'),
        local('Neue Montreal'),
        url(${NeueMontrealItalicWoff2}) format('woff2'),
        url(${NeueMontrealItalicWoff}) format('woff'),
        url(${NeueMontrealItalicOtf}) format('opentype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'NeueMontreal-Italic';
    src: local('NeueMontreal-Italic'),
        url(${NeueMontrealItalicWoff2}) format('woff2'),
        url(${NeueMontrealItalicWoff}) format('woff'),
        url(${NeueMontrealItalicOtf}) format('opentype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: local('NeueMontreal-Light'),
        local('Neue Montreal'),
        url(${NeueMontrealLightWoff2}) format('woff2'),
        url(${NeueMontrealLightWoff}) format('woff'),
        url(${NeueMontrealLightOtf}) format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'NeueMontreal-Light';
    src: local('NeueMontreal-Light'),
        url(${NeueMontrealLightWoff2}) format('woff2'),
        url(${NeueMontrealLightWoff}) format('woff'),
        url(${NeueMontrealLightOtf}) format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: local('NeueMontreal-LightItalic'),
        local('Neue Montreal'),
        url(${NeueMontrealLightItalicWoff2}) format('woff2'),
        url(${NeueMontrealLightItalicWoff}) format('woff'),
        url(${NeueMontrealLightItalicOtf}) format('opentype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'NeueMontreal-LightItalic';
    src: local('NeueMontreal-LightItalic'),
        url(${NeueMontrealLightItalicWoff2}) format('woff2'),
        url(${NeueMontrealLightItalicWoff}) format('woff'),
        url(${NeueMontrealLightItalicOtf}) format('opentype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: local('NeueMontreal-Medium'),
        local('Neue Montreal'),
        url(${NeueMontrealMediumWoff2}) format('woff2'),
        url(${NeueMontrealMediumWoff}) format('woff'),
        url(${NeueMontrealMediumOtf}) format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'NeueMontreal-Medium';
    src: local('NeueMontreal-Medium'),
        url(${NeueMontrealMediumWoff2}) format('woff2'),
        url(${NeueMontrealMediumWoff}) format('woff'),
        url(${NeueMontrealMediumOtf}) format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: local('NeueMontreal-MediumItalic'),
        local('Neue Montreal'),
        url(${NeueMontrealMediumItalicWoff2}) format('woff2'),
        url(${NeueMontrealMediumItalicWoff}) format('woff'),
        url(${NeueMontrealMediumItalicOtf}) format('opentype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'NeueMontreal-MediumItalic';
    src: local('NeueMontreal-MediumItalic'),
        url(${NeueMontrealMediumItalicWoff2}) format('woff2'),
        url(${NeueMontrealMediumItalicWoff}) format('woff'),
        url(${NeueMontrealMediumItalicOtf}) format('opentype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: local('NeueMontreal-Regular'),
        local('Neue Montreal'),
        url(${NeueMontrealRegularWoff2}) format('woff2'),
        url(${NeueMontrealRegularWoff}) format('woff'),
        url(${NeueMontrealRegularOtf}) format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'NeueMontreal-Regular';
    src: local('NeueMontreal-Regular'),
        url(${NeueMontrealRegularWoff2}) format('woff2'),
        url(${NeueMontrealRegularWoff}) format('woff'),
        url(${NeueMontrealRegularOtf}) format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
`, () => true);

export default WebFonts;
