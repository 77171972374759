import styled, { keyframes } from 'styled-components';
import {
  transparentize,
  tint,
  lighten,
} from 'polished';
import { withStyledSystem } from '@metropolis-io/theme';
import type {
  StyledSystemProps,
} from '@metropolis-io/theme';

export const Box = styled.div<StyledSystemProps>`
  flex: 1;
  position: relative;
  color: ${({ theme }) => theme.colors.blue6};
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 4px;
  line-height: 1;
  ${withStyledSystem}
`;

type StyledWithErrorProps = StyledSystemProps & { hasError: boolean, showPlaceholder?: boolean };

export const StyledLabel = styled.label<StyledWithErrorProps>`
  position: absolute;
  left: 0;
  top: 4px;
  font-family: "Neue Montreal", NeueMontreal-Medium, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: .02em;
  user-select: none;
  transform-origin: 0 50%;
  pointer-events: none;
  transform: translate3d(0, 20px, 0) scale3d(1,1,1);
  transition: 150ms transform ease;
  color: ${({ theme, hasError }) => (hasError ? theme.colors.coral : theme.colors.blue6)};
  ${withStyledSystem}
`;

export const StyledInput = styled.input<StyledWithErrorProps>`
  position: relative;
  z-index: 1;
  display: inline-flex;
  height: 48px;
  padding-top: 20px;
  width: 100%;
  flex: 1;
  font-size: 17px;
  line-height: 20px;
  border: 0;
  outline: 0;
  padding-left: 0;




  margin-bottom: 3px;
  font-family: "Neue Montreal", NeueMontreal-Medium, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  font-weight: 500;
  background: transparent;
  border-bottom: 1px solid ${({ theme, hasError }) => (hasError ? theme.colors.coral : theme.colors.grey3)};
  border-radius: 0;
  color: ${({ theme, hasError }) => (hasError ? theme.colors.pink3 : theme.colors.black)};
  caret-color: ${({ theme, hasError }) => (hasError ? theme.colors.coral : theme.colors.blue6)};
  transition: color 150ms linear, border-bottom 100ms linear;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
  }

  &::placeholder {
    color: transparent;
    opacity: 0;
    font-size: 17px;
    font-weight: 500;
  }

  &:focus {
    outline: 0;
    border-bottom-color: ${({ theme }) => theme.colors.blue6};

    &::placeholder {
      color: ${({ theme, showPlaceholder }) => (showPlaceholder ? theme.colors.grey6 : 'transparent')};
      opacity: ${({ showPlaceholder }) => (showPlaceholder ? 1 : 0)};
      transition: ${({ showPlaceholder }) => (showPlaceholder ? 'opacity 200ms linear 150ms' : 'none')};
    }
  }

  &:focus ~ ${StyledLabel},
  &:not(:placeholder-shown) ~ ${StyledLabel} {
    transform: translate3d(0, 0, 0) scale3d(0.875, 0.875, 1);
  }

  &:disabled {
    color: ${({ theme }) => transparentize(0.2, theme.colors.black)};
    border-bottom-color: ${({ theme }) => transparentize(0.4, theme.colors.grey3)};
    cursor: not-allowed;

    & ~ ${StyledLabel} {
      opacity: 0.9;
      color: ${({ theme }) => tint(0.1, lighten(0.15, theme.colors.blue7))};
    }
  }

  /* Change Autocomplete styles in Chrome */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.grey7};
    box-shadow: 0 0 0 1000px rgba(0,0,0,0) inset;
    transition: background-color 5000s ease-in-out 0s;

    & ~ ${StyledLabel} {
      transform: translate3d(0, 0, 0) scale3d(0.875, 0.875, 1);
    }
  }

  ${withStyledSystem}
`;

const revealMessage = keyframes`
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 16px;
    opacity: 1;
  }
`;

export const Message = styled.div<StyledWithErrorProps>`
  color: ${({ theme, hasError }) => (hasError ? theme.colors.orange5 : theme.colors.grey6)};
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  transition: color .25s;
  letter-spacing: 0.015em;
  text-align: left;
  animation: ${revealMessage} 250ms ease-out 0ms 1 backwards;
  ${withStyledSystem}
`;
