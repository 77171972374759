import shared from './env/shared';
import local from './env/local';
import development from './env/development';
import staging from './env/staging';
import production from './env/production';

const environments = {
  local,
  development,
  staging,
  production,
};

export type EnvironmentType = 'local' | 'development' | 'staging' | 'production';

type ConfigType = {
  CONFIG_ENV?: EnvironmentType;

  DATA_REFRESH_INTERVAL: number;

  GOOGLE_MAP_API_KEY: string;
  GOOGLE_OAUTH_CLIENT_ID: string;

  MAPBOX_ACCESS_TOKEN: string;

  SQUARE_CLIENT_ID: string;
  SQUARE_CALLBACK_URL?: string;

  SITE_API_BASE_URL?: string;
  SITE_PHOTO_API_BASE_URL?: string;

  GEOLOCATION_IP_API?: string;

  STRIPE_PUBLISHABLE_KEY: string;

  HELP_URL: string;

  OPTIMIZELY_SDK_KEY: string;

  QR_IO_API_KEY: string;
};

type OverrideFnArgs =
  | Partial<ConfigType>
  | ((env: EnvironmentType | null, config: ConfigType) => Partial<ConfigType>);

class Configurator {
  config: ConfigType;

  environment: EnvironmentType | null;

  constructor() {
    this.config = { ...shared };
    this.environment = null;
  }

  setEnv = (env: EnvironmentType): void => {
    const { environment: prevEnv } = this;
    this.environment = env;

    if (prevEnv !== env) {
      this.build();
    }
  };

  build = (): void => {
    if (!this.environment) {
      throw new Error('`setEnv()` must be called with config environment');
    }
    this.config = {
      ...shared,
      ...environments[this.environment],
      CONFIG_ENV: this.environment,
    };
    Object.freeze(this.config);
  };

  getConfig = (): ConfigType => {
    if (!this.environment) {
      throw new Error('`setEnv()` must be called with config environment');
    }
    return this.config;
  };

  getEnv = (): EnvironmentType | null => this.environment;

  override = (args: OverrideFnArgs): void => {
    const config = this.getConfig();
    const environment = this.getEnv();
    if (typeof args === 'function') {
      this.config = {
        ...config,
        ...args(environment, config),
      };
    }
    this.config = {
      ...config,
      ...args,
    };
  };
}

export default Configurator;
