/* TODO: Move these to theme package */
import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const slideInFromRight = keyframes`
  0% {
    transform: translate3d(100%,0,0);
  }
  100% {
    transform: translate3d(0,0,0);
  }
`;

export const slideInFromLeft = keyframes`
  0% {
    transform: translate3d(-100%,0,0);
  }
  100% {
    transform: translate3d(0,0,0);
  }
`;

export const slideOutToRight = keyframes`
  0% {
    transform: translate3d(0,0,0);
  }
  90% {
    opacity: 1;
  }
  95%{
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translate3d(100%,0,0);
  }
`;

export const slideOutToLeft = keyframes`
  0% {
    transform: translate3d(0,0,0);
  }
  90% {
    opacity: 1;
  }
  95%{
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translate3d(-100%,0,0);
  }
`;

export const fadeSlideInFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(50%,0,0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
`;

export const fadeSlideInFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(-50%,0,0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
`;

export const fadeSlideOutToRight = keyframes`
  0% {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
  100% {
    opacity: 0;
    transform: translate3d(50%,0,0);
  }
`;

export const fadeSlideOutToLeft = keyframes`
  0% {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
  100% {
    opacity: 0;
    transform: translate3d(-50%,0,0);
  }
`;
