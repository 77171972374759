import type { IconProps } from './Icon';

export function iconSize(props: IconProps) {
  const width = props.size ?? props.width ?? null;
  const height = props.size ?? props.height ?? null;

  let widthValue: string = '';
  let heightValue: string = '';

  if (width !== null) {
    if (typeof width === 'number') {
      widthValue = `${width}px`;
    } else if (typeof width === 'string') {
      widthValue = width;
    }
  }
  if (height !== null) {
    if (typeof height === 'number') {
      heightValue = `${height}px`;
    } else if (typeof height === 'string') {
      heightValue = height;
    }
  }

  return {
    width: widthValue,
    height: heightValue,
  };
}
