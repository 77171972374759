const DATA_REFRESH_INTERVAL: number = 5000;

const GOOGLE_MAP_API_KEY: string = 'AIzaSyBodWHmmQdIPkVejpAlJlK65xNRZB3G588';

const GOOGLE_OAUTH_CLIENT_ID: string = '882771224751-q5f3d01cg4jcrbbdng6qtsqbqkm6hamb.apps.googleusercontent.com';

const MAPBOX_ACCESS_TOKEN: string = 'pk.eyJ1IjoibWV0cm8tdGl3ZW4iLCJhIjoiY2p5OHlvazExMDA4aTNjbWJsZzlhbDlmcSJ9.nZNwyzQOdD4OFohCBw-7Wg';

const SQUARE_CLIENT_ID: string = 'sq0idp-XBafNc36avncpCyccLVOPA';

const HELP_URL: string = 'https://support.metropolis.io';

const SITE_PHOTO_API_BASE_URL: string = 'https://site-photo.metropolis.io/api/photos';

const GEOLOCATION_IP_API: string = 'https://pro.ip-api.com/json?key=LvRVf5SmtQbN1Wp';

const STRIPE_PUBLISHABLE_KEY: string = 'pk_test_51HulvBCXl2cS0UTVQGzGc9dt9NgRKznVArev0M07ndcZ59NS1c1rSilpHT6O9BNTyiS7pKcxTsJUH13SnVtCAOzV00meBCOPrs';

const OPTIMIZELY_SDK_KEY: string = 'DRDgy2bPb5XevPGazuZFe';

const QR_IO_API_KEY: string = 'h6SLmBe8taxdpInijwf9';

const sharedConfig = {
  DATA_REFRESH_INTERVAL,
  GOOGLE_MAP_API_KEY,
  GOOGLE_OAUTH_CLIENT_ID,
  MAPBOX_ACCESS_TOKEN,
  HELP_URL,
  SITE_PHOTO_API_BASE_URL,
  SQUARE_CLIENT_ID,
  GEOLOCATION_IP_API,
  STRIPE_PUBLISHABLE_KEY,
  OPTIMIZELY_SDK_KEY,
  QR_IO_API_KEY,
};

export default sharedConfig;
