import { system } from 'styled-system';
import type {
  ResponsiveValue,
  TypographyProps,
  Theme,
} from 'styled-system';

import * as CSS from 'csstype';

export interface FontProps extends TypographyProps {
  font?: ResponsiveValue<CSS.Property.FontFamily, Theme>;
}
const fontAlias = system({
  font: {
    property: 'fontFamily',
    scale: 'fonts',
  },
});

export default fontAlias;
