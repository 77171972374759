import letterSpacings from './letterSpacings';
import type { LetterSpacings } from './letterSpacings';

export type { LetterSpacings };

const fonts = {
  /** Neue Montreal Light */
  light: 'NeueMontreal-Light',

  /** Neue Montreal Light Italic */
  lightItalic: 'NeueMontreal-LightItalic',

  /** Neue Montreal Regular */
  regular: 'NeueMontreal-Regular',

  /** Neue Montreal Italic */
  italic: 'NeueMontreal-Italic',

  /** Neue Montreal Medium */
  medium: 'NeueMontreal-Medium',

  /** Neue Montreal Medium Italic */
  mediumItalic: 'NeueMontreal-MediumItalic',

  /** Neue Montreal Bold */
  bold: 'NeueMontreal-Bold',

  /** Neue Montreal Bold Italic */
  boldItalic: 'NeueMontreal-BoldItalic',
};

export type Fonts = (
  | 'light'
  | 'lightItalic'
  | 'regular'
  | 'italic'
  | 'medium'
  | 'mediumItalic'
  | 'bold'
  | 'boldItalic'
) | string;
export type FontSizes = {
  /** 12px */
  tiny: string;

  /** 14px */
  small: string;

  /** 15px */
  bodySmall: string;

  /** 16px */
  body: string;

  /** 17px */
  bodyMedium: string;

  /** 18px */
  h6: string;

  /** 19px */
  bodyXLarge: string;

  /** 20px */
  h5: string;

  /** 22px */
  headingSmall: string;

  /** 24px */
  h4: string;

  /** 30px */
  h3: string;

  /** 32px */
  large: string;

  /** 36px */
  h2: string;

  /** 40px */
  h1: string;

  /** 44px */
  xLarge: string;

  /** 48px */
  huge: string;
};

const fontSizes: FontSizes = {
  tiny: '12px',

  small: '14px',

  bodySmall: '15px',

  body: '16px',

  bodyMedium: '17px',

  h6: '18px',

  bodyXLarge: '19px',

  h5: '20px',

  headingSmall: '22px',

  h4: '24px',

  h3: '30px',

  large: '32px',

  h2: '36px',

  h1: '40px',

  xLarge: '44px',

  huge: '48px',
};

const typography = {
  fonts,
  fontSizes,
  letterSpacings,
};
export default typography;
