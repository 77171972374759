import type { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';
import { withStyledSystem } from '@metropolis-io/theme';
import type {
  StyledSystemProps,
  AnimatedStyledComponent,
} from '@metropolis-io/theme';

export type BoxProps = HTMLAttributes<HTMLDivElement> & StyledSystemProps;

export const Box = styled.div<StyledSystemProps>`
  ${withStyledSystem}
`;
Box.defaultProps = {
  position: 'relative',
  display: 'flex',
  flex: 1,
  lineHeight: 1,
};

export const AnimatedBox: AnimatedStyledComponent<typeof Box> = animated(Box);
