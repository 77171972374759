import type { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { withStyledSystem } from '@metropolis-io/theme';
import type {
  StyledSystemProps,
} from '@metropolis-io/theme';

export type HeadingProps = HTMLAttributes<HTMLHeadingElement> & StyledSystemProps;

const commonStyles = css`
  line-height: 1;
  text-rendering: optimizeLegibility;
  user-select: none;
`;

export const Heading = styled.h1<StyledSystemProps>`
  color: ${(props) => props.theme.colors.blue7};
  letter-spacing: 0.02em;
  font-size: 36px;
  font-family: "Neue Montreal", NeueMontreal-Bold, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  font-weight: 700;
  ${commonStyles}
  ${withStyledSystem}
`;

export const SubHeading = styled.h3<StyledSystemProps>`
  color: ${(props) => props.theme.colors.grey5};
  text-transform: uppercase;
  margin-bottom: 16px;
  letter-spacing: 0.125em;
  font-family: "Neue Montreal", NeueMontreal-Bold, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  font-weight: 700;
  font-size: 14px;
  ${commonStyles}
  ${withStyledSystem}
`;
