import styled, { css } from 'styled-components';
import {
  withStyledSystem,
} from '@metropolis-io/theme';
import type {
  StyledSystemProps,
} from '@metropolis-io/theme';

const baseCss = css`
  appearance: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: transparent;
  cursor: pointer;
  outline: 0;
  padding: 0;
  padding-left: 14px;
  padding-right: 14px;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  box-sizing: border-box;
  height: 40px;
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.03em;
  border-radius: 0;
  text-rendering: geometricPrecision;
  border: 0;
  color: ${({ theme }) => theme.colors.grey6};

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const activeCss = css`
  color: ${({ theme }) => theme.colors.blue6};

  &:disabled {
    color: ${({ theme }) => theme.colors.grey6};
  }
`;

export const baseStyle = css<{ active?: boolean }>`
  ${baseCss}
  ${({ active }) => (active ? activeCss : '')}
`;

type StyledLabelProps = StyledSystemProps & { active?: boolean };

export const LabelButtonBase = styled.button<StyledLabelProps>`
  ${baseStyle}
  ${withStyledSystem}
`;
