const SITE_API_BASE_URL: string = 'https://site.metropolis.io/api';

const GOOGLE_OAUTH_CLIENT_ID: string = '482980396840-pp6khfu6oab5tf5im72p8n5pmdhdt503.apps.googleusercontent.com';

const SQUARE_CALLBACK_URL: string = 'https://site.metropolis.io/payment/callback/square';

const MAPBOX_ACCESS_TOKEN: string = 'pk.eyJ1IjoibWV0cm9wb2xpcy1lbmdpbmVlcmluZyIsImEiOiJjazBzZmE3cGswMG5jM2VuMDlmMjVpcjl3In0.ZRgB0_fbR7uAqycuWqrrpw';

const STRIPE_PUBLISHABLE_KEY: string = 'pk_live_51HulvBCXl2cS0UTV6h4F3wiiJXxjv6jch3jo7fb3Hi8RlPcBvrEhPdVdyiLHVkCHYE7AMldgyGY98HhqbxQ10mZj001lKwwAPs';

const OPTIMIZELY_SDK_KEY: string = 'Fe144jtsTCZuCvxQcUyiv';

const productionConfig = {
  SITE_API_BASE_URL,
  GOOGLE_OAUTH_CLIENT_ID,
  SQUARE_CALLBACK_URL,
  MAPBOX_ACCESS_TOKEN,
  STRIPE_PUBLISHABLE_KEY,
  OPTIMIZELY_SDK_KEY,
};

export default productionConfig;
