/* eslint-disable no-param-reassign */
import {
  forwardRef,
  useRef,
  InputHTMLAttributes,
  ReactNode,
} from 'react';
import type { StyledSystemProps } from '@metropolis-io/theme';
import {
  Box,
  StyledInput,
  StyledLabel,
  Message,
} from './TextInput.styled';
import type { BoxProps } from '../Box';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: ReactNode;
  name: string;
  error?: ReactNode;
  message?: ReactNode;
  children?: ReactNode;
  containerProps?: BoxProps;
  labelProps?: StyledSystemProps;
  messageProps?: StyledSystemProps;
}

const defaultProps = {
  message: '',
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({
  id,
  label,
  name,
  error,
  message,
  children,
  containerProps,
  labelProps,
  messageProps,
  ...inputProps
}, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);

  // If ref is passed, ensure it has the input element
  if (ref && typeof ref === 'function') {
    ref(inputRef.current);
  } else if (ref && ref.current) {
    ref.current = inputRef.current;
  }

  // Ensure label positioning if input has value
  const labelTransfrom = inputRef.current?.value ? 'translate3d(0, 0, 0) scale3d(0.875, 0.875, 1)' : undefined;

  return (
    <Box {...containerProps}>
      <StyledInput
        id={id ?? name}
        hasError={!!error}
        name={name}
        ref={inputRef}
        type="text"
        autoComplete="none"
        step="any" // add this so when type="number" the CSS pseudo class :valid will still work
        data-testid="TextInput-StyledInput"
        placeholder={name}
        showPlaceholder={
          Boolean(
            inputProps.placeholder
            && inputProps.placeholder !== name,
          )
        }
        {...inputProps}
      />
      <StyledLabel
        hasError={!!error}
        htmlFor={id ?? name}
        style={{
          transform: labelTransfrom,
        }}
        {...labelProps}
      >
        <span>{label}</span>
      </StyledLabel>
      {(error || message) && (
        <Message hasError={!!error}>{error ?? message}</Message>
      )}
      {children}
    </Box>
  );
});

TextInput.defaultProps = defaultProps;
