const SITE_API_BASE_URL: string = 'http://localhost:9000/api';

const GOOGLE_OAUTH_CLIENT_ID: string = '482980396840-pp6khfu6oab5tf5im72p8n5pmdhdt503.apps.googleusercontent.com';

const SQUARE_CALLBACK_URL: string = 'http://localhost:9000/payment/callback/square';

const localConfig = {
  SITE_API_BASE_URL,
  GOOGLE_OAUTH_CLIENT_ID,
  SQUARE_CALLBACK_URL,
};

export default localConfig;
